<template>
	<div>
		<div class="d-flex">
			<span class="display-1 mb-3">
				{{ company.name }}
			</span>
		</div>
		<div class="d-flex justify-left mb-3">
			<v-btn class="mr-3" icon :href="company.info.website" target="_blank">
				<v-icon>mdi-earth</v-icon>
			</v-btn>
			<span class="body-2 mx-3"> <v-icon>mdi-account-group</v-icon> {{ $t('companies.numEmployees', { num: company.info.employees.name }) }} </span>
		</div>
		<div class="d-flex justify-left mb-3">
			<template v-for="(socialAccount, socialNetwork) in company.social">
				<div class="px-1 text-center" :key="socialNetwork" v-if="socialAccount">
					<v-btn icon @click="callGoToSocial(socialAccount, socialNetwork)">
						<v-icon>mdi-{{ socialNetwork }}</v-icon>
					</v-btn>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CompanyInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		...mapGetters({
			company: 'companies/company',
		}),
	},
	methods: {
		callGoToSocial(socialAccount, socialNetwork) {
			window.open(`https://${socialNetwork}.com/${socialAccount}`, '_blank')
			this.goToSocial({ companyID: this.company.id, socialNetwork })
		},
		...mapActions('companies', ['goToSocial']),
	},
}
</script>
